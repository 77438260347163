import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Popover,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { CatalogPlanItemRowVM } from '../../../view-models/widgets/plan-catalog-widget/CatalogPlanItemRowVM'
import LocalizationStore from '../../../../localization/LocalizationStore'
import './TrainingPlanRow.scss'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded'
import WavesRoundedIcon from '@material-ui/icons/WavesRounded'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import EditIcon from '@material-ui/icons/Edit'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import ImageIcon from '@material-ui/icons/Image'
import Avatar from '../../../../shared/Avatar'
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded'
import { CatalogPlanRowVM } from '../../../view-models/widgets/plan-catalog-widget/CatalogPlanRowVM'

interface Props {
  plan: CatalogPlanRowVM
  key: number
  localizationStore?: LocalizationStore
}

const TrainingPlanRow: React.FC<Props> = ({ plan, key, localizationStore }) => {
  const [isExpanded, setExpanded] = React.useState(false)

  const { training_plans: strings } = localizationStore.lzStrings

  const renderContentItemRow = (contentItem: string, index) => {
    return (
      <Grid item key={index} xs={12} className='content-item-row'>
        <Typography variant='body1' className='content-item-text'>
          {index + 1}. {contentItem}
        </Typography>
      </Grid>
    )
  }

  const renderThumbnail = () => {
    if (plan.thumbnail) return <img src={plan.thumbnail} className='thumbnail' />
    if (!plan.thumbnail)
      return (
        <div className='default-thumbnail'>
          <ImageIcon />
        </div>
      )
  }

  const renderFlowIndicator = () => {
    if (!plan.isForTrainingFlow) return null
    return (
      <div className='flow-container'>
        <Tooltip
          title={strings.training_plan_automated_info}
          placement='right'
          enterDelay={500}
        >
          <WavesRoundedIcon className={'flow-icon'} />
        </Tooltip>
      </div>
    )
  }

  const renderItemRow = (item: CatalogPlanItemRowVM, index: number) => {
    const renderIcon = () => {
      if (isExpanded) return <ExpandMoreRoundedIcon />
      if (!isExpanded) return <ChevronRightIcon />
    }
    return (
      <Grid item xs={12} key={index}>
        <Accordion expanded={isExpanded} id='TrainingPlanItemRow'>
          <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant='h6'
                  className='subtitle'
                  onClick={() => setExpanded(!isExpanded)}
                >
                  {renderIcon()}
                  {item.title}
                </Typography>
              </Grid>
              <Grid item xs={12} className='subtitle'></Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className='content-items-container'>
            <Grid container>
              {item.contentItemNames.map((name, index) => renderContentItemRow(name, index))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }

  const renderEditIcon = () => {
    if (!plan.canManageTrainingPlanFlows) return null
    return (
      <Tooltip title='Edit Training Plan' placement='bottom' enterDelay={100}>
        <IconButton size='small' onClick={() => plan.createTrainingPlan()}>
          <EditIcon className='edit' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderExpandPlanIcon = () => {
    if (isExpanded) return <ExpandLessRoundedIcon />
    if (!isExpanded) return <ExpandMoreRoundedIcon />
  }

  const renderTrainerMenu = plan => {
    if (!plan.trainer) return null
    return (
      <Popover
        open={Boolean(plan.trainerAnchorEl)}
        anchorEl={plan.trainerAnchorEl}
        onClose={e => {
          plan.setTrainerAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='trainer-menu'
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' className='trainer-label'>
              Assigned Trainer
            </Typography>
            <Typography variant='subtitle2' className='trainer-label'>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <span className='trainer-container'>
              <Avatar user={plan.trainer} />
              <Typography variant='h6'>{plan.trainer.name}</Typography>
            </span>
            <a className='mail-link' href={plan.mailToLink} target='_blank'>
              <MailOutlineIcon className='mail' />
            </a>
          </Grid>
        </Grid>
      </Popover>
    )
  }

  const renderTrainerIcon = () => {
    if (!plan.trainer) return null
    return (
      <PeopleAltIcon className='trainer' onClick={e => plan.setTrainerAnchorEl(e.currentTarget)} />
    )
  }

  return (
    <>
      <Accordion key={key} expanded={isExpanded} id='CatalogPlanRow'>
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          {renderThumbnail()}
          <Grid container>
            <Grid item xs={12} className='title-row'>
              <Typography variant='h6' className='title-text'>
                {plan.title}
              </Typography>
              {renderFlowIndicator()}
              <span className='icons'>
                {renderEditIcon()}
                <Tooltip title='Assign this plan to new users' placement='bottom' enterDelay={100}>
                  <GroupAddRoundedIcon
                    className='assign'
                    fontSize='medium'
                    onClick={() => plan.assignCatalogPlan()}
                  />
                </Tooltip>
              </span>
            </Grid>
            <Grid item xs={12} className='subtitle' onClick={() => setExpanded(!isExpanded)}>
              <Typography variant='body1' className='subtitle-text'>
                {plan.subTitle}
              </Typography>

              <div className='expand-icon'>{renderExpandPlanIcon()}</div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {plan.trainingPlanItems.map((item, index) => {
              return renderItemRow(item, index)
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {renderTrainerMenu(plan)}
    </>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(TrainingPlanRow))
