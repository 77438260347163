import { RootStore } from '../../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { TrainingPlanRowVM } from './TrainingPlanRowVM'

export class ManageTrainingsWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public sortType: string = 'Date'
  @observable public tabIndex: number = 0
  @observable public isSnackbarOpen: boolean
  @observable public snackbarMessage: string = ''

  @computed
  public get rows(): TrainingPlanRowVM[] {
    if (this.tabIndex === 0) return this.planRows
    if (this.tabIndex === 1) return this.archivedRows
  }

  @computed
  public get planRows(): TrainingPlanRowVM[] {
    return this.rootStore.trainingPlansStore.orgTrainingPlans
      .filter(
        (trainingPlan) =>
          trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId ||
          this.rootStore.appStore.isOrgAdmin
      )
      .filter((plan) => !plan.isCatalogPlan && !plan.isArchived)
      .filter((plan) => plan.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .map((e) => new TrainingPlanRowVM(this.rootStore, this, e))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  @computed
  public get archivedRows(): TrainingPlanRowVM[] {
    return this.rootStore.trainingPlansStore.orgTrainingPlans
      .filter(
        (trainingPlan) =>
          trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId ||
          this.rootStore.appStore.isOrgAdmin
      )
      .filter((plan) => !plan.isCatalogPlan && plan.isArchived)
      .filter((plan) => plan.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .map((e) => new TrainingPlanRowVM(this.rootStore, this, e))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  @action
  public setTabIndex(num: number) {
    this.tabIndex = num
  }

  @action setSortType(type) {
    this.sortType = type
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
  }

  @action createNewTrainingPlan() {
    this.rootStore.appStore.router.push('/trainingPlans/edit/new')
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }
}
export default ManageTrainingsWidgetVM
